import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/main/home" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _ctx.saveProduct,
                    color: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("tallenna ")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Tuote "),
                  (_ctx.product)
                    ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.product.name), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (!_ctx.product)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(" Ladataan ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.product)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    sizeXl: "8"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            placeholder: "nimi",
                            modelValue: _ctx.product.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.product.name) = $event)),
                            label: "nimi",
                            "label-placement": "stacked"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.product.code,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.product.code) = $event)),
                            label: "tuotekoodi",
                            "label-placement": "stacked"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            modelValue: _ctx.product.barcode,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.product.barcode) = $event)),
                            label: "viivakoodi",
                            "label-placement": "stacked"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            type: "number",
                            step: "1",
                            inputmode: "number",
                            modelValue: _ctx.product.orderKey,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.product.orderKey) = $event)),
                            label: "järjestysnumero",
                            "label-placement": "stacked"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_textarea, {
                            modelValue: _ctx.product.description,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.product.description) = $event)),
                            label: "lyhyt kuvaus",
                            "label-placement": "stacked"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_textarea, {
                            modelValue: _ctx.product.fullDescription,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.product.fullDescription) = $event)),
                            label: "pidempi kuvaus",
                            "label-placement": "stacked"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            type: "number",
                            step: "0.05",
                            inputmode: "decimal",
                            modelValue: _ctx.product.price,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.product.price) = $event)),
                            label: "hinta",
                            "label-placement": "stacked"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_checkbox, {
                            slot: "start",
                            color: "primary",
                            modelValue: _ctx.product.isActive,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.product.isActive) = $event)),
                            "label-placement": "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("tuote käytettävissä")
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            "css-class": "wide-select",
                            class: "select-full-width",
                            multiple: true,
                            modelValue: _ctx.product.categoryIdList,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.product.categoryIdList) = $event)),
                            placeholder: "valitse kategoriat",
                            "ok-text": "Valitse",
                            "cancel-text": "peru",
                            label: "kategoriat",
                            "label-placement": "stacked"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (c) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: c.id,
                                  value: c.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(c.title), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            "css-class": "wide-select",
                            class: "select-full-width",
                            multiple: false,
                            modelValue: _ctx.product.accountId,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.product.accountId) = $event)),
                            placeholder: "valitse tili",
                            "ok-text": "Valitse",
                            "cancel-text": "peru",
                            label: "tiliöinti",
                            "label-placement": "stacked"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accounts, (c) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: c.id,
                                  value: c.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(c.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select, {
                            "css-class": "wide-select",
                            class: "select-full-width",
                            multiple: true,
                            modelValue: _ctx.product.organizationIdList,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.product.organizationIdList) = $event)),
                            placeholder: "valitse toimipaikat",
                            "ok-text": "Valitse",
                            "cancel-text": "peru",
                            label: "toimipaikat",
                            "label-placement": "stacked"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizations, (c) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: c.id,
                                  value: c.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(c.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}