import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12ac1769"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.category?.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.eventTemplates.length==0)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 0,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Ladataan ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.eventTemplates.length>0)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 1,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventTemplates, (eventTemplate) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: eventTemplate.description
                }, [
                  _createElementVNode("h2", null, _toDisplayString(eventTemplate.descriptionTitle), 1),
                  _createElementVNode("div", {
                    class: "event-description",
                    innerHTML: eventTemplate.description
                  }, null, 8, _hoisted_1),
                  (eventTemplate.instances)
                    ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(eventTemplate.instances, (eventInstance) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: eventInstance.id,
                              class: "ion-no-padding col-xs"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(eventInstance.name) + " ", 1),
                                    (eventInstance.ageMin)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(eventInstance.ageMin), 1))
                                      : _createCommentVNode("", true),
                                    _createTextVNode(" - "),
                                    (eventInstance.ageMax)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(eventInstance.ageMax), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024),
                                (eventInstance.nextInstance)
                                  ? (_openBlock(), _createBlock(_component_ion_label, {
                                      key: 0,
                                      class: "ion-text-wrap"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.momentjs(eventInstance.nextInstance).format('dddd H:mm (D.M.)')), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(eventInstance.location), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_button, {
                                  expand: "full",
                                  onClick: ($event: any) => (_ctx.registerToEvent(eventInstance))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("ilmoittaudu")
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}