
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { ActionTypes, useStore } from "../store";
import { PaymentFullModel, PaymentSelectModel } from "@/models/PaymentModels";
import { useErrorBox } from "@/components/errorBox";
import { useCheckoutUtils } from "@/common/CheckoutUtils";
import { useFinancialStore } from "@/store/financialStore";

export default defineComponent({
  name: "CheckOut3",
  components: {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonPage,
    IonTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonButton,
    IonContent,
    IonToolbar,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const financial = useFinancialStore();
    const {showError} = useErrorBox();
    const {refreshCart} = useCheckoutUtils();

    const paymentOptions: Ref<PaymentSelectModel[]> = ref([]);
    const shoppingCart = computed(() =>  store.getters.cart);
    const paymentFullModel: Ref<PaymentFullModel|undefined> = ref(undefined);

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    } 
    
    onMounted( async () => {
      const ok = await refreshCart();
      if(!ok)
        return;
        
      const l = await loading();
      try {


        const r = await store.dispatch(ActionTypes.GET_PAYMENT_OPTIONS,undefined);
        paymentOptions.value = r;
        console.log(shoppingCart.value);
        // Just select first option
        const e = new CustomEvent<any>("foo",{detail:{value:r[0].code}});
        await startPayment(e);
      } catch ( reason) {
        showError(reason as string, true);
      } finally {
                l.dismiss();
      }

    });

    // const continueCheckout = () => {
    //   const request = shoppingCart.value.toShoppingCartRequest(shoppingCart.value);
    //   store.dispatch(ActionTypes.GET_PAYMENT_FULL_MODEL,request)
    //     .then((result) => {
    //       paymentFullModel.value = result;
    //     })
    //     .catch(reason => {
    //       showError(reason,true);
    //       if(shoppingCart.value) {
    //         shoppingCart.value.type = "";
    //       }
    //     });
    // };

    const approvePayment = async () => {
      const l = await loading();
      try {
        const cart = await financial.getShoppingCart(shoppingCart.value?.id ?? "");
        if(!cart || !paymentFullModel.value)
          return;

        cart.type = paymentFullModel.value.cartType;
        cart.state = paymentFullModel.value.nextState;

        await store.dispatch(ActionTypes.SAVE_SHOPPING_CART, cart);

        showError("Lasku lähetty.",false);
        router.replace('/');

      } catch (err) {
        showError(err as string,true);
      } finally {
        l.dismiss();
      }

    };

    const startPayment = async (paymenent: CustomEvent<any>) => {
      if(shoppingCart.value) {
        shoppingCart.value.type = paymenent.detail.value;

        if(shoppingCart.value.type){

          try {
            const request = shoppingCart.value.toShoppingCartRequest(shoppingCart.value);
            const result = await store.dispatch(ActionTypes.GET_PAYMENT_FULL_MODEL,request);
            console.log(result);
            paymentFullModel.value = result;
          } catch ( reason) {
            showError(reason as string, true);
            paymentFullModel.value = undefined;
            if(shoppingCart.value) {
              shoppingCart.value.type = "";
            }
          }
        }
      }
    }

    return {
      router,
      paymentOptions,
      shoppingCart,      
      // startPayment,
      paymentFullModel,
      // continueCheckout,
      approvePayment,
    };
  },
});
