import { StorageSerializers, useLocalStorage } from "@vueuse/core";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { getNow, postNow, prettyResponse, useStore } from ".";
import { EditShoppingCartMessage } from "@/models/ShoppingCart";
import { ShoppingCartIndex2 } from "@/models/ShoppingCartIndex";
import { PaymentDailyCheckModel } from "@/models/SalesOfTheDay";



export const useFinancialStore = defineStore('financial', {
    state: () => ({
      test: useLocalStorage("test", "",{ serializer: StorageSerializers.object,  mergeDefaults: true  }),
    }),
  
    getters: {
    }, 
  
    actions: {
      async getShoppingCart(organizationId: string) {
        try {
            const params = new URLSearchParams([['id', organizationId]]);
            const r = await getNow(`shoppingcart/get`, params) as AxiosResponse<EditShoppingCartMessage>;

            if(r.data.success===true) {
                return r.data.item;
              } else {
                throw new Error((r as any).data.Message);
              }
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async removeShoppingCart(id: string, force?: boolean) {
        const params = new URLSearchParams([ ['id', id]]);
        if(force) params.append("force", "true");

        try {
          await getNow(`shoppingcart/remove`, params);
          return;
        }
        catch (reason) {
          throw (prettyResponse(reason,(message)=>{
            switch(message){
              case "Status error":
                return "Tässä tilassa olevaa ostosta ei voi poistaa.";
                default:
                  return message;
            }}));
          }
      },

      async removePayment(id: string, force?: boolean) {
        const params = new URLSearchParams([ ['id', id]]);
        if(force) params.append("force", "true");

        try {
          await getNow(`shoppingcart/removePayment`, params);
          return;
        }
        catch (reason) {
          throw (prettyResponse(reason,(message)=>{
            switch(message){
              case "Status error":
                return "Tässä tilassa olevaa ostosta ei voi poistaa.";
                default:
                  return message;
            }}));
          }
      },

      async removeShoppingCartLine(id: string, force?: boolean) {
        const params = new URLSearchParams([ ['id', id]]);
        if(force) params.append("force", "true");

        try {
          await getNow(`shoppingcart/removeLine`, params);
          return;
        }
        catch (reason) {
          throw (prettyResponse(reason,(message)=>{
            switch(message){
              case "Status error":
                return "Tässä tilassa olevaa ostosta ei voi poistaa.";
                default:
                  return message;
            }}));
          }
      },

      async getShoppingCartsOfDay(type: string, from: Date, to: Date) {
        const store = useStore();
        try {
            const params = new URLSearchParams([ ['type', type],
            ['from', from.toJSON()],
            ['to', to.toJSON()],
            ['organizationId', store.state.organization.id]]);

            const r = await getNow(`shoppingcart/DetailsList`, params) as AxiosResponse<Array<ShoppingCartIndex2>>;

            const list = new Array<ShoppingCartIndex2>();         
            r.data.forEach(i=>{
              const r = (new ShoppingCartIndex2).fromResponse(i);
              list.push(r);
            },this);
            return list;

        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async printReceipt(cartId: string) {
        const store = useStore();
        try {
            const params = new URLSearchParams([['cartId', cartId], ['organizationId', store.state.organization.id]]);
            await getNow(`shoppingcart/PrintReceipt`, params);
            return;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async emailReceipt(cartId: string, email: string) {
        try {
            const params = new URLSearchParams([['cartId', cartId], ['email', email]]);
            await getNow(`shoppingcart/EmailReceipt`, params);
            return;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async getPaymentDailyCheck(source: string, from: Date) {
        const store = useStore();
        try {
          const params = new URLSearchParams([ ['source', source],
          ['date', from.toJSON()],
          ['organizationId', store.state.organization.id]]);

          const r = await getNow(`shoppingcart/PaymentDailyCheckJson`, params) as AxiosResponse<PaymentDailyCheckModel>;
          return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async updatePaymentDailyCheck(payload: PaymentDailyCheckModel) {
        try {          
          const r = await postNow(`shoppingcart/PaymentDailyCheckJson`, payload) as AxiosResponse<PaymentDailyCheckModel>;
          return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

    }
  })