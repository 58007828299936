
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent } from 'vue';
import MenuComponent from './components/menu.vue'
export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    MenuComponent,
  }
});

//<ion-vue-router id="main"></ion-vue-router>
