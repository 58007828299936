import { OrganizationInfo } from "./Organization";
import { ReservationViewModel } from "./ReservationViewModel";
import { ShoppingCartLineViewModel } from "./ShoppingCart";
import { TicketInfo } from "./Ticket";
import * as moment from 'moment-timezone';
import { createGuid } from "@/common/utils";

export class UserCategoryInfo {
    id: string;
    title: string;
    constructor() {
        this.id = "";
        this.title = "";
    }
}

export class UserCategoryFullInfo extends UserCategoryInfo {
    discountPs: number;
    createTime: Date;
    editTime: Date;
    editor: string;

    constructor() {
        super();
        this.editor = "";
        this.discountPs = 0;
        this.createTime = new Date();
        this.editTime = new Date();
    }
}

export class UserProfileViewModel 
{
    userId: number|undefined;
    userName: string|undefined;

    street: string|undefined;
    streetLine1: string|undefined;
    streetLine2: string|undefined;
    postCode: string|undefined;
    city: string|undefined;
    
    country: string|undefined;   
    firstName: string|undefined;
    lastName: string|undefined;
    companyName: string|undefined;
    phone: string|undefined;
    email: string|undefined;
    comments: string|undefined;
    invoiceDetails: string|undefined;
    editTime: Date;

    sportsCredit: number;
    vatCode: string|undefined;
    selected: boolean;

    constructor(){
        this.editTime = new Date();
        this.selected = false;
        this.sportsCredit = 0;
    }

    fromResponse = (from: UserProfileViewModel) => {
        this.userId = from.userId;
        this.userName = from.userName;
        this.city = from.city;
        this.street = from.street;
        this.streetLine1 = from.streetLine1;
        this.streetLine2 = from.streetLine2;
        this.postCode = from.postCode;
        this.country = from.country;
        this.firstName = from.firstName;
        this.lastName = from.lastName;
        this.companyName = from.companyName;
        this.phone = from.phone;
        this.email = from.email;
        this.comments = from.comments;
        this.invoiceDetails = from.invoiceDetails;
        this.sportsCredit = from.sportsCredit;        
        this.vatCode = from.vatCode;        
        if(from.editTime){ this.editTime = new Date(from.editTime);}
        return this;
    }
}

export class UserPaymentViewModel{
    id: string;
    added: Date;
    source: string;
    amount: number;
    rounding: number;
    notReturned: number;
    returned: number;
    received: Date | undefined;
    account: any;
    reference: string;
    organizationId: string | undefined;

    // Readonly
    fixedAmount: number;

    constructor() {
        this.amount = 0;
        this.rounding = 0;
        this.notReturned = 0;
        this.returned = 0;
        this.fixedAmount = 0;
        this.added = new Date();
        this.id = "";
        this.source = "";
        this.reference = "";
    }

    update = () => {
        this.fixedAmount = this.amount - this.returned - this.notReturned - this.rounding;
    }
}

export class ShoppingCartSimpleViewModel
{
    selected: boolean;
    id: string;
    state: string;
    number: number | undefined;
    type: string;
    organizationId: string | undefined;
    userPayment: Array<UserPaymentViewModel>;
    shoppingCartLines: Array<ShoppingCartLineViewModel>;

    // Readonly
    total: number | undefined;

    constructor() {
        this.id = "";
        this.state = "";
        this.type = "";
        this.userPayment = [];
        this.shoppingCartLines = [];
        this.selected = false;
    }
}

export class UserDetailedViewModel extends UserProfileViewModel   {
    familyMembers: Array<FamilyMemberDetailedViewModel>;
    openShoppingCarts: Array<ShoppingCartSimpleViewModel>;
    reservations: Array<ReservationViewModel>;
    roles: Array<string>;
    organisations: Array<OrganizationInfo>; 
    categories: Array<UserCategoryInfo>;

    constructor() {
        super();
        this.familyMembers = [];
        this.openShoppingCarts = [];
        this.reservations = [];
        this.roles = [];
        this.organisations = [];
        this.categories = [];
    }

    fromResponse2 = (from: UserDetailedViewModel) => {
        // sort and filter reservations
        const momentjs: any = moment;
        moment.locale("fi");
        const yesterday = momentjs().add(-24, 'h');
        from.reservations.forEach(i=>{
            const r = (new ReservationViewModel).fromResponse(i);
            const beginTimeMoment =  momentjs(r.eventInstanceBeginTime);  
            if(beginTimeMoment > yesterday){
                this.reservations.push(r);
            }

        },this);
        this.reservations.sort((i,j)=>(i.eventInstanceBeginTime ?? new Date()).getTime()-(j.eventInstanceBeginTime?? new Date()).getTime());

        this.familyMembers = from.familyMembers;
        this.openShoppingCarts = from.openShoppingCarts;
        this.roles = from.roles;
        this.organisations = from.organisations;
        this.fromResponse(from);
        this.categories = from.categories;

        return this;
    }
}

export class FamilyMemberViewModel {
    rulesApproved: Date | undefined;
    id: string;
    firstName: string;
    lastName: string;
    birthDay: Date | undefined;
    cardId: string;
    age: string | undefined;
    comments: string;
    internalComments: string;
    memberNumber: number | undefined;
    constructor() {
        this.id = createGuid();
        this.firstName = "";
        this.lastName = "";
        this.comments = "";
        this.internalComments = "";
        this.cardId = "";
    }

    fromResponse = (from: FamilyMemberViewModel) => {
        if(from.rulesApproved){ this.rulesApproved = new Date(from.rulesApproved);}
        this.id = from.id;
        this.firstName = from.firstName;
        this.lastName = from.lastName;
        if(from.birthDay){ this.birthDay = new Date(from.birthDay);}
        this.cardId = from.cardId;
        this.age = from.age;
        this.comments = from.comments;
        this.internalComments = from.internalComments;
        this.memberNumber = from.memberNumber;
        return this;
    }
}
export class OtherFamily
{
    name: string;
    type: string;
    userId: number;

    constructor(){
        this.name = "";
        this.type = "";
        this.userId = 0;
    }
}

export class FamilyMemberDetailedViewModel extends FamilyMemberViewModel {
    tickets: Array<TicketInfo>;
    otherFamilies: Array<OtherFamily>;
    type: string;
    action: string;

    constructor() {
        super();
        this.tickets = [];
        this.otherFamilies = [];
        this.type = "";
        this.action = "";
    }
    
    fromResponse2 = (from: FamilyMemberDetailedViewModel) => {
        from.tickets.forEach(i=>{
            this.tickets.push((new TicketInfo).fromResponse(i));
        },this);

        this.tickets.sort((a,b)=>(a.issued ?? new Date()).getTime()-(b.issued ?? new Date()).getTime())
        this.otherFamilies = from.otherFamilies;
        this.type = from.type;
        this.action = from.action;

        return this;
    }
}




