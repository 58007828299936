import { StorageSerializers, useLocalStorage } from "@vueuse/core";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { getNow, prettyResponse } from ".";

export class HistoryItem {
  time: Date = new Date();
  notes = "";
}

export const useUserStore = defineStore('user', {
    state: () => ({
      test: useLocalStorage("test", "",{ serializer: StorageSerializers.object,  mergeDefaults: true  }),
    }),
  
    getters: {
    }, 
  
    actions: {
      async getHistoryItems(userId: number) {
        try {
            const params = new URLSearchParams([['id', userId.toString()]]);
            const r = await getNow(`UserProfile/ProfileHistory`, params) as AxiosResponse<Array<HistoryItem>>;
            return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },        


    }
  })