
import {
  IonMenu,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonContent,
  IonListHeader,
  IonMenuToggle,
  IonIcon,
  IonFooter,
  IonButton,
  IonSelect,
  IonSelectOption,
} from "@ionic/vue";
import { ActionTypes, useStore } from "../store";
import { useRouter } from 'vue-router';
import { locationOutline, home, happyOutline,bodyOutline, hammerOutline, bookOutline,
        calendar,peopleOutline, cartOutline, receiptOutline } from 'ionicons/icons';
import { computed, onMounted, Ref, ref } from "vue";
import { EventSeasonInfo } from "@/models/EventSeasonInfo";
import { useUserStateUtils } from '@/common/UserStateUtils';
export default {
  name: "MenuComponent",
  components: {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonList,
    IonItem,
    IonContent,
    IonListHeader,
    IonMenuToggle,
    IonIcon,
    IonLabel,
    IonFooter,
    IonButton,
    IonSelect,
    IonSelectOption,
    },
  setup(){
    const router = useRouter();
    const store = useStore();
    const {verifyAuth} = useUserStateUtils();
    const isActive = (_path: string) => {
      return router.currentRoute.value.path == _path;
    };

    const hasRoleInstructor = computed(() => { 
      return store.getters.userInfo.roles?.indexOf("Instructor") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Admin") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Merchant") >= 0;});    
    
    const cart = computed(() => store.getters.cart);

    const hasRoleMerchant = computed(() => { return store.getters.userInfo.roles?.indexOf("Merchant") >= 0;});
    const hasRoleCheckin = computed(() => { return store.getters.userInfo.roles?.indexOf("CheckinOnly") >= 0;});
    const hasRoleAdmins = computed(() => { return store.getters.userInfo.roles?.indexOf("Admin") >= 0;});

    const currentUser = computed(() => store.state.user);
    const currentOrganization = computed(() => store.state.organization);

    const seasonState: Ref<Array<EventSeasonInfo>> = ref([]);


    const logout = async () =>  {
        await store.dispatch(ActionTypes.LOGOUT,undefined);
        router.replace("/login");
    }

    onMounted(() =>{
      store.dispatch(ActionTypes.GET_EVENT_SEASONS,{type:"Lapset",status:"Open"})
      .then((season)=>{seasonState.value = season;})
      .catch((err)=>{
console.log(err);
      });

      verifyAuth();

    });

    const selectOrganisation = (item: CustomEvent) => {
          if(item.detail.value){
            const o = store.state.user.organizations.find(i=>i.id == item.detail.value)
            if(o && o.id != currentOrganization.value.id){
              store.dispatch(ActionTypes.SELECT_ORG, o);
            }
          }
    };

    return {
        store,cart,
        isActive,
        logout,
        currentUser,
        router,
        hasRoleMerchant,hasRoleCheckin,hasRoleInstructor,hasRoleAdmins,
        selectOrganisation,
        currentOrganization,
        locationOutline,peopleOutline,receiptOutline,calendar,home,happyOutline,hammerOutline,bookOutline,bodyOutline,cartOutline,
        seasonState,
      }
  }
};
