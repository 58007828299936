
import {
  IonDatetime,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonButton,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonCol,
  loadingController,
  IonDatetimeButton,
  IonModal,
  IonIcon,
  IonInput,
toastController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref, watchEffect } from "vue";
import { calendarOutline, printOutline, sendOutline } from 'ionicons/icons';
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { useErrorBox } from '@/components/errorBox';
import { ShoppingCartIndex2 } from "@/models/ShoppingCartIndex";
import * as moment from 'moment-timezone';
import { useFinancialStore } from "@/store/financialStore";

export default defineComponent({
  name: "ShoppingCartsPage",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonButton,
    IonDatetime,
    IonIcon,
    IonMenuButton,
    IonRefresher,
    IonRefresherContent,
    IonText,
    IonCol,
    IonDatetimeButton,
    IonModal,
    IonInput,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const financial = useFinancialStore();
    const {showError} = useErrorBox();
    const momentjs: any = moment;

    const email: Ref<string> = ref("");

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    const date: Ref<Date> = ref(today);
    const organization = computed(() => store.state.organization);
    const cartList: Ref<Array<ShoppingCartIndex2>> = ref([]);

    const hasRoleInstructor = computed(() => { 
      return store.getters.userInfo.roles?.indexOf("Accounting") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Merchant") >= 0 ||
             store.getters.userInfo.roles?.indexOf("Admin") >= 0;});

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }

    const loadCarts = async (firstDate: Date) => {
      const l = await loading();
      try {
        const nextDay = new Date(firstDate.getTime() + 24 * 60 * 60 * 1000);
        const response = await financial.getShoppingCartsOfDay("kassa", firstDate, nextDay);
        if(response)
          cartList.value = response;
      } catch (err){
        showError(err as string,true); 
      } finally {
        l.dismiss();
      }
    };

    watchEffect( async () => {
      if(!store.state.organization.id || store.state.organization.id === ""){
        showError("Toimipaikkaa ei löydy.",true);
      }
      await loadCarts(date.value);
    });

    onMounted(() => {
      date.value = today;
    });
   
    const selectDate = (message: CustomEvent) => {
      date.value = new Date(message.detail.value)
    };

    const refreshNow = async (item: any) => {
      await loadCarts(date.value);
      item.target.complete();
    };

    const printReceipt = async (cart: ShoppingCartIndex2) => {
      const l = await loading();
      try{
       await financial.printReceipt(cart.id);
       const toast = await toastController.create({
          message: "Tulostettu",
          duration: 1500,
          position: "top",
          color: "light",
        });
        await toast.present();
      } catch (err){
        showError(err as string,true);
      } finally {
        l.dismiss();
      }
    };

    const sendEmail = async (cart: ShoppingCartIndex2) => {
      const l = await loading();
      try{
        if(!email.value || email.value.length < 5){
          showError("Sähköposti tarvitaan",true);
          return;
        }
        await financial.emailReceipt(cart.id, email.value);
        const toast = await toastController.create({
          message: "Lähetetty",
          duration: 1500,
          position: "top",
          color: "light",
        });
        await toast.present();

      } catch (err){
        showError(err as string,true);
      } finally {
        l.dismiss();
      }

    };

    
    return {
      router,
      organization,
      selectDate,
      calendarOutline,printOutline,sendOutline,
      date,
      hasRoleInstructor,
      refreshNow,
      cartList,
      momentjs,
      printReceipt,sendEmail,
      email,
    };
  },
});
