import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7d90f04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_EventInstanceButton = _resolveComponent("EventInstanceButton")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_TicketButton = _resolveComponent("TicketButton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.cardreader)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  class: "center",
                  onClick: _ctx.moveBack
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"]),
                    _createTextVNode("takaisin")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.activeReservationsNew)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_2, "varauksesi tänään"))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, { style: {"display":"flex","flex-wrap":"wrap"} }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeReservationsNew, (eventVm) => {
                return (_openBlock(), _createBlock(_component_EventInstanceButton, {
                  onReservationclick: _ctx.selectReservationEvent,
                  key: eventVm.id,
                  reservationVm: eventVm
                }, null, 8, ["onReservationclick", "reservationVm"]))
              }), 128))
            ]),
            _: 1
          }),
          _createElementVNode("h2", null, "kortit ja jäsenyytesi (" + _toDisplayString(_ctx.organization.name) + ")", 1),
          _createVNode(_component_ion_list, { style: {"display":"flex","flex-wrap":"wrap"} }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeTicketsNew, (ticketVm) => {
                return (_openBlock(), _createBlock(_component_TicketButton, {
                  class: _normalizeClass({ selected: ticketVm.id==_ctx.selectedTicket?.id }),
                  onClick: ($event: any) => (_ctx.selectTicket(ticketVm)),
                  key: ticketVm.id,
                  ticket: ticketVm,
                  member: ticketVm.familyMember
                }, null, 8, ["class", "onClick", "ticket", "member"]))
              }), 128))
            ]),
            _: 1
          }),
          (_ctx.selectedTicket)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "seuraavat alkavat tunnit"))
            : _createCommentVNode("", true),
          (_ctx.selectedTicket && _ctx.activeEventsNew.length===0)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_4, "tunteja ei löytynyt"))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_list, { style: {"display":"flex","flex-wrap":"wrap"} }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeEventsNew, (eventVm) => {
                return (_openBlock(), _createBlock(_component_EventInstanceButton, {
                  onInstanceclick: _ctx.selectEvent,
                  key: eventVm.id,
                  eventInstance: eventVm
                }, null, 8, ["onInstanceclick", "eventInstance"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}