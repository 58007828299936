import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withKeys as _withKeys, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df912f9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "productButtonContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  (_ctx.currentCart?.userProfile)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.replace('/customer/'+_ctx.currentCart.userProfile?.userId)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Takaisin")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  (_ctx.organisation)
                    ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.organisation.name), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.currentCart?.userProfile)
                    ? (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.currentCart?.userProfile?.firstName) + " " + _toDisplayString(_ctx.currentCart?.userProfile?.lastName), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              (!_ctx.readOnly)
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 0,
                    size: "12",
                    sizeLg: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            size: "12",
                            sizeLg: "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_select, {
                                    placeholder: "valitse",
                                    onIonChange: _ctx.selectCategory,
                                    label: "Tuoteryhmä"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
                                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                                          key: category.id,
                                          value: category.id
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(category.title), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["onIonChange"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, {
                            size: "12",
                            sizeLg: "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    modelValue: _ctx.filterTxt,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterTxt) = $event)),
                                    "aria-placeholder": "etsi",
                                    onKeyup: _withKeys(_ctx.takeFirst, ["enter"]),
                                    label: "Hae"
                                  }, null, 8, ["modelValue", "onKeyup"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_list, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                            return (_openBlock(), _createBlock(_component_ion_button, {
                              class: "productButton",
                              key: product.id,
                              onClick: ($event: any) => (_ctx.addNewLine(product))
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_1, [
                                  _createVNode(_component_ion_text, {
                                    slot: "start",
                                    class: "productName"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(product.name), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_ion_text, {
                                    slot: "end",
                                    class: "productPrice"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(product.price.toFixed(2)), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_col, {
                size: "12",
                sizeLg: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cartLines, (line) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: line.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(line.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(line.quantity) + "kpl", 1)
                              ]),
                              _: 2
                            }, 1024),
                            (!_ctx.readOnly)
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 0,
                                  onClick: ($event: any) => (line.quantity=(line.quantity??0)+1)
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            (!_ctx.readOnly)
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 1,
                                  onClick: ($event: any) => (line.quantity=(line.quantity??0)-1)
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, { icon: _ctx.removeOutline }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString((((line.price??0) - (line.discount??0))*(line.quantity??0)).toFixed(2)) + "€", 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_button, {
                              onClick: ($event: any) => (_ctx.editLine(line))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.pencilOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, { class: "totalTxt" }, {
                            default: _withCtx(() => [
                              _createTextVNode("Yhteensä " + _toDisplayString(_ctx.totalPrice.toFixed(2)) + "€", 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (!_ctx.readOnly)
                    ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                size: "medium",
                                color: "success",
                                expand: "block",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.moneyReceiveVisible=true))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Siirry maksamaan")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (_ctx.isExisting)
                            ? (_openBlock(), _createBlock(_component_ion_col, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_button, {
                                    size: "medium",
                                    expand: "block",
                                    color: "secondary",
                                    onClick: _ctx.leaveOpen
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Jätä auki")
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                size: "medium",
                                color: "warning",
                                expand: "block",
                                onClick: _ctx.clearCart
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Tyhjennä")
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_popover, {
                    "css-class": "cart-pop-over",
                    "backdrop-dismiss": "false",
                    "is-open": _ctx.moneyReceiveVisible
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_grid, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_header, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Vastaanota rahat tai korttimaksu asiakkaalta")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Maksamatta " + _toDisplayString(_ctx.totalUnpaid.toFixed(2)) + "€", 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_text, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode("summa")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    inputmode: "decimal",
                                    modelValue: _ctx.receivingMoney,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.receivingMoney) = $event)),
                                    placeholder: "0.0"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_text, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode("€")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accounts, (account) => {
                                    return (_openBlock(), _createBlock(_component_ion_button, {
                                      key: account.id,
                                      onClick: ($event: any) => (_ctx.addPayment(account))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(account.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payments, (payment) => {
                            return (_openBlock(), _createBlock(_component_ion_row, {
                              key: payment.id
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(payment.fixedAmount.toFixed(2)) + "€", 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_text, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(payment.account.name), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_button, {
                                      onClick: ($event: any) => (_ctx.removePayment(payment))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128)),
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_button, {
                                    color: "success",
                                    disabled: _ctx.totalUnpaid>0.03,
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.moneyReceived(true)))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { icon: _ctx.checkmarkOutline }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_text, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" hyväksy")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_button, {
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.moneyReceived(false)))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, { icon: _ctx.chevronBackOutline }, null, 8, ["icon"]),
                                      _createVNode(_component_ion_text, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" peru")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["is-open"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_modal, {
            "is-open": _ctx.editingLineOpen,
            "css-class": "my-custom-class",
            onDidDismiss: _ctx.doneEditingLine
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.editingLine?.name), 1)
                    ]),
                    _: 1
                  }),
                  (_ctx.editingLine)
                    ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { position: "floating" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("otsikko")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_input, {
                                modelValue: _ctx.editingLine.name,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editingLine.name) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { position: "floating" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("kpl hinta")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_input, {
                                type: "number",
                                modelValue: _ctx.editingLine.price,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editingLine.price) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { position: "floating" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("kpl alennus €")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_input, {
                                type: "number",
                                step: "0.01",
                                modelValue: _ctx.editingLine.discount,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editingLine.discount) = $event))
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_ion_button, {
                                slot: "end",
                                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.editingLine.discount=(_ctx.editingLine.price*0.1).toFixed(2)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("10%")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                slot: "end",
                                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.editingLine.discount=(_ctx.editingLine.price*0.2).toFixed(2)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("20%")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                slot: "end",
                                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.editingLine.discount=(_ctx.editingLine.price*0.25).toFixed(2)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("25%")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                slot: "end",
                                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.editingLine.discount=(_ctx.editingLine.price*0.30).toFixed(2)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("30%")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                slot: "end",
                                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.editingLine.discount=(_ctx.editingLine.price*0.50).toFixed(2)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("50%")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { position: "floating" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("kpl")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_input, {
                                type: "number",
                                step: "1",
                                modelValue: _ctx.editingLine.quantity,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editingLine.quantity) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { position: "floating" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("alv %")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_input, {
                                type: "number",
                                step: "1",
                                modelValue: _ctx.editingLine.vatPersentage,
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editingLine.vatPersentage) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["is-open", "onDidDismiss"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}