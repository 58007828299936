
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonButton,
  IonList,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonAvatar,
  IonCheckbox,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { ActionTypes, useSessionObject, useStore } from "../store";
import { FamilyMemberDetailedViewModel, UserDetailedViewModel } from "@/models/UserDetailedViewModel";
import { personCircleOutline, personAddOutline, peopleOutline,cartOutline } from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useCheckoutUtils } from "@/common/CheckoutUtils";
import { createGuid } from "@/common/utils";

export default defineComponent({
  name: "CheckOut2",
  components: {
    IonBackButton,
    IonButtons,
    IonHeader,
    IonPage,
    IonTitle,
    IonInput,
    IonLabel,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonButton,
    IonContent,
    IonToolbar,
    IonAvatar,
    IonIcon,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonCheckbox,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();
    const {refreshCart} = useCheckoutUtils();

    const userProfile: Ref<UserDetailedViewModel|undefined> = ref(undefined);
    const shoppingCart = computed(() =>  store.getters.cart);
    const fullProfile = computed(() =>  store.getters.fullProfile);
    const password1: Ref<string> = ref("");
    const password2: Ref<string> = ref("");
    const samePerson: Ref<boolean> = ref(false);


    const shoppingCartUniqueRows = computed(() =>  {
      const map = new Map();
      for (const row of store.getters.cart.rows) {
        map.set(row.ticketId, row);
      }
      const iteratorValues = map.values();
      return [...iteratorValues];
    });

    const step: Ref<number> = ref(0);

    const selectingRow: Ref<string|undefined> = ref(undefined);

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    } 

    onMounted(async () => {
      const l = await loading();
      try {        
        if(fullProfile.value){
          // User is already registered

          const r = await store.dispatch(ActionTypes.REFRESH_FULL_PROFILE,true);
          // debugger;
          userProfile.value = r;

          await refreshCart();  
          
          if(step.value === 0)
            step.value = 1;

        } else {
          // New user
          userProfile.value = new UserDetailedViewModel();

          // const m = new FamilyMemberDetailedViewModel();
          // m.id = createGuid();
          // m.firstName = "Uusi"
          // m.lastName = "Käyttäjä"
          // userProfile.value.familyMembers.push(m);
        }
      } catch (error) {
        await showError(error as string, true);
      } finally {
        l.dismiss();
      }

    });

    const addFamilyMember = () => {
      if(!userProfile.value)
        return;
        const m = new FamilyMemberDetailedViewModel();
        m.id = createGuid();
      userProfile.value.familyMembers.push(m);
    }

    const registerNew = async () => {
      step.value += 1;
    }
    
    const stepMoveNext = async () => {

    // Copy or create familymembers
    if(step.value === 1 && samePerson.value && userProfile.value){


      let fm = userProfile.value.familyMembers.find((j)=>
        userProfile.value && j.firstName && j.lastName &&
        j.firstName === userProfile.value.firstName && 
        j.lastName === userProfile.value.lastName);

        if(!fm){
          fm = new FamilyMemberDetailedViewModel();
          fm.id = createGuid();
          fm.firstName = userProfile.value.firstName ?? "";
          fm.lastName = userProfile.value.lastName ?? "";
          userProfile.value.familyMembers.push(fm);
        }

      shoppingCart.value.rows.forEach((r)=>{
        if(r.ticketId){
          r.memberId = fm?.id;
        }
      })        
    }

    step.value += 1;
   }

   const stepMovePrevious = async () => {
    step.value -= 1;
   }

    const saveMoveNext = async () => {
      if(!userProfile.value?.userId && userProfile.value){        
      // User create needed
        const l = await loading();
        try {
          await store.dispatch(ActionTypes.REGISTER2, { 
            login: {username: userProfile.value?.email ?? "",password:password1.value},
            user:userProfile.value});

          router.replace('/checkout3');
        } catch(error){
          await showError(error as string, true);
        } finally {
          l.dismiss();
        }

      } else if ( userProfile.value ) {
        // Save changes anyway
        const l = await loading();
        try {
          await store.dispatch(ActionTypes.UPDATE_FULL_PROFILE, userProfile.value);
          router.replace('/checkout3');
        } catch(error){
          await showError(error as string, true);       
        } finally {
             l.dismiss();
        }
      } 
    }

    const selectFamilyMember = (rowId: string, fm: undefined|FamilyMemberDetailedViewModel) => {
      const selectedRow = shoppingCart.value.rows.find((i)=>i.id===rowId);
      if(!selectedRow || !userProfile.value)
        return;

      if(!fm) {
        fm = new FamilyMemberDetailedViewModel();
        fm.id = createGuid();
        fm.firstName = ""
        fm.lastName = ""
        userProfile.value.familyMembers.push(fm);
      }

      selectedRow.memberId = fm.id;
      selectingRow.value = undefined;
    }

    const emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
    const canMoveNext1 = computed(() =>  {
       if(!userProfile.value)
         return "Virhe";

      if(!userProfile.value.firstName || userProfile.value.firstName === "")
        return "Etunimi puuttuu";

      if(!userProfile.value.lastName || userProfile.value.lastName === "")
        return "Sukunimi puuttuu";

      if(!userProfile.value.street || userProfile.value.street === "")
        return "Katuosoite puuttuu";

      if(!userProfile.value.postCode || userProfile.value.postCode === "")
        return "Postinumero puuttuu";

      if(!userProfile.value.city || userProfile.value.city === "")
        return "Kaupunki puuttuu";

      if(!userProfile.value.email || userProfile.value.email === "")
        return "Sähköposti puuttuu";

      if(!emailRegex.test(userProfile.value.email))
        return "Tarkista sähköposti";

      // Password required
      if(!userProfile.value.userId)
      {
        if(password1.value.length < 6)
          return "Salasana on liian lyhyt";

        if (password1.value != password2.value)
          return "Salasanoissa eroa";
      }

      return "";
    });

    const canMoveNext2 = computed(() =>  {
      if(!userProfile.value)
        return false;

      let ok = true;

      userProfile.value.familyMembers.forEach(fm => {
        if(fm.firstName === "" || fm.lastName === "")
          ok = false;
        });

      if(!ok)
        return false;

      const missing = shoppingCartUniqueRows.value.find(i=>i.memberId ==undefined);
      if(missing != undefined) { return false;}



      return true;
    });

    const getFamilyMember = (id: string)=>{
      // debugger;
      if(!userProfile.value)
        return [];

      const list = userProfile.value.familyMembers.find((i)=>i.id===id)
      if(!list)
        return [];
      
      // debugger;
      return [ list];
    }

    const selectDate = (fm: FamilyMemberDetailedViewModel, e: CustomEvent<any>)=> {
      fm.birthDay = e.detail.value;
    };

    const redirectToLogin = () =>{
      const session = useSessionObject();
      session.enteredRoute = router.currentRoute.value.fullPath;
      router.replace('/login');
    }

    return {
      router,
      step,
      samePerson,
      userProfile,
      shoppingCart,shoppingCartUniqueRows,
      selectFamilyMember,
      canMoveNext1,canMoveNext2,
      addFamilyMember,
      password1, password2,
      saveMoveNext,selectDate,
      stepMoveNext,stepMovePrevious,
      getFamilyMember,selectingRow,
      personCircleOutline, personAddOutline, peopleOutline,cartOutline,
      redirectToLogin,
      registerNew,
    };
  },
});
